import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartJson } from "@/types/Cart.type";
import { StoreJson } from "@/types/Store.type";
import Pos from "@/common/constants/pos";
import { getOrders } from "@/lib/api/shop/orders";
import { ORDER_STATUS, ORDER_TYPE } from "@/common/constants/order";
import {
	OrderBodyListJson,
	OrderFilter,
	OrderJson,
	SERVICE_TYPE,
} from "@/types/Orders.type";
import { AxiosError, AxiosResponse } from "axios";
import { createOrderUser } from "@/lib/api/shop/cart";

type InitialState = {
	activeIndex: number;
	storeDetail: StoreJson | null;
	openVat: boolean;
	loading: boolean;
	listOrder: OrderJson[];
	orderTotal: number;
	tabNav: NavPos;
	toggle: {
		openMenuNav: boolean;
		openInforOrder: boolean;
	};
};
const initialState: InitialState = {
	activeIndex: 0,
	storeDetail: null,
	openVat: false,
	loading: false,
	listOrder: [],
	orderTotal: 0,
	tabNav: "sale",
	toggle: {
		openInforOrder: false,
		openMenuNav: false,
	},
};

export type NavPos = "sale" | "order";

export const fetchData = createAsyncThunk<
	Pick<
		AxiosResponse<OrderBodyListJson, any>,
		"data" | "headers" | "status" | "statusText"
	>,
	OrderFilter,
	{ rejectValue: string }
>("data/fetchData", async (filters, { rejectWithValue }) => {
	try {
		const response = await getOrders(filters);
		if (!response) {
			throw new Error("Network response was not ok");
		}

		return response;
	} catch (error: any) {
		return rejectWithValue(error.message || "An unknown error occurred");
	}
});

export const createOrderPos = createAsyncThunk<
	Pick<
		AxiosResponse<OrderJson, any>,
		"data" | "headers" | "status" | "statusText"
	>,
	{ storeId: number },
	{ rejectValue: string }
>("data/createOrderPos", async (filters, { rejectWithValue }) => {
	try {
		const resOrder = await createOrderUser({
			store_id: filters.storeId,
			type_sale: "employee",
			service_type: SERVICE_TYPE.PURTCHASE_METHOD_IN_STORE,
		});

		if (!resOrder) {
			throw new Error("Network response was not ok");
		}

		return resOrder;
	} catch (error: any) {
		return rejectWithValue(error.message || "An unknown error occurred");
	}
});

const PosSlice = createSlice({
	name: "order",
	initialState: initialState,
	reducers: {
		setActiveIndex: (state, action) => {
			state.activeIndex = action.payload;
		},
		setStoreDetail: (state, action) => {
			state.storeDetail = action.payload;
		},

		setListOrder: (state, { payload }: { payload: OrderJson[] }) => {
			state.listOrder = payload;
			state.orderTotal = payload.length;
		},

		addOrderPos: (state, { payload }: { payload: OrderJson }) => {
			state.listOrder = [...state.listOrder].concat(payload);
			state.orderTotal = state.orderTotal++;
		},

		deleteOrderPos: (state, { payload }: { payload: string | number }) => {
			const n = [...state.listOrder.filter((i) => i.id !== payload)];
			state.listOrder = n;
			state.orderTotal = state.orderTotal--;
		},

		setOrderTotal: (state, { payload }: { payload: number }) => {
			state.orderTotal = payload;
		},

		setOpenVat: (state, { payload }: { payload: boolean }) => {
			state.openVat = payload;
		},

		setTabNav: (state, { payload }: { payload: "order" | "sale" }) => {
			state.tabNav = payload;
		},

		toggleMenuNav: (state) => {
			state.toggle.openMenuNav = !state.toggle.openMenuNav;
		},
		toggleInforOrder: (state) => {
			state.toggle.openInforOrder = !state.toggle.openInforOrder;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchData.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				fetchData.fulfilled,
				(
					state,
					action: PayloadAction<
						Pick<
							AxiosResponse<OrderBodyListJson, any>,
							"data" | "headers" | "status" | "statusText"
						>
					>
				) => {
					state.loading = false;
					state.listOrder = action.payload.data.items.map((i) => ({
						...i,
						details: {
							data: [],
							total: 0,
						},
					}));
					state.orderTotal = action.payload.data.total;
				}
			)
			.addCase(fetchData.rejected, (state, action: PayloadAction<any>) => {
				state.loading = false;
				// state.error = action.payload || 'Failed to fetch data';
			})
			.addCase(createOrderPos.fulfilled, (state, action) => {
				// Assume action.payload.data contains the order list
				const orderRes = action.payload.data;

				const dataListOrderNew = [...state.listOrder].concat(orderRes);
				state.listOrder = dataListOrderNew;
				state.activeIndex = dataListOrderNew.length - 1;

				// Dispatch the action to update the order list
				// Note: Inside extraReducers, you cannot directly call dispatch
				// This is a conceptual representation; dispatch needs to be done outside
				// Use middleware, effects, or additional logic to dispatch actions

				// If the above direct dispatch doesn't apply, manage effects from a saga or middleware
			})
			.addCase(createOrderPos.rejected, (state, action) => {
				console.log("rejected");
			});
	},
});

export const {
	setActiveIndex,
	setStoreDetail,
	setOpenVat,
	addOrderPos,
	deleteOrderPos,
	setTabNav,
	toggleMenuNav,
	toggleInforOrder,
	setListOrder,
	setOrderTotal,
} = PosSlice.actions;

export default PosSlice.reducer;
