"use client";
import { useAppDispatch } from "@/redux/hook";
import { useEffect, useLayoutEffect } from "react";
import { Toaster } from "sonner";
import {
	getDataFromLocalStorage,
	setDataLanguage,
	setDevice,
	setLanguage,
	setSystemSetting,
} from "@/redux/features/ShopSlice";
import { Device, SystemSetting } from "@/types/Shop.type";
import { LanguageSetting } from "@/common/constants/language";
import { DataLanguageCache } from "@/common/types/SiteLanguage.type";
import Local from "@/common/constants/local";
import Helper from "@/utils/helper";
import useStoreId from "@/lib/hooks/useStoreId";
import dynamic from "next/dynamic";
import Aos from "aos";
import { useDebounce } from "use-debounce";
import { debounce } from "lodash";

const FloatButtonPage = dynamic(() => import("../FloatButtonPage"), {
	ssr: false,
});
const PopupsPage = dynamic(() => import("../PopupsPage"), { ssr: false });

export default function InitProvider({
	children,
	systemSetting,
	device,
}: {
	children: React.ReactNode;
	systemSetting: SystemSetting;
	device: Device;
}) {
	const dispatch = useAppDispatch();

	const detectDeviceByScreenSize = debounce(() => {
		// if (!Helper.isServer()) {
		let defaultDevice: Device = "desktop";
		const width = window.innerWidth;

		if (width <= 767) {
			defaultDevice = "mobile";
			// return "mobile"; // kích thước nhỏ hơn hoặc bằng 767px là mobile
		} else if (width >= 768 && width <= 1024) {
			defaultDevice = "desktop";

			// return "tablet"; // từ 768px đến 1024px là tablet
		} else {
			defaultDevice = "desktop";

			// return "desktop"; // lớn hơn 1024px thì là desktop
		}

		dispatch(setDevice(defaultDevice));
		// }
	}, 500);
	function init() {
		const lang = systemSetting || LanguageSetting[0];
		dispatch(setSystemSetting(systemSetting));
		dispatch(setLanguage(systemSetting));

		// cache language setting
		const dataLocal = getDataFromLocalStorage<DataLanguageCache>(
			Local.cookies.language
		);
		const dataClear = {
			space: [],
			data: {},
			lang: lang.lang,
			version: lang.version,
		};
		if (dataLocal) {
			if (dataLocal.lang !== lang.lang || dataLocal.version !== lang.version) {
				if (!Helper.isServer()) {
					localStorage.setItem(
						Local.cookies.language,
						JSON.stringify(dataClear)
					);
				}
			} else {
				dispatch(setDataLanguage(dataLocal));
			}
		} else {
			localStorage.setItem(Local.cookies.language, JSON.stringify(dataClear));
		}
	}

	useLayoutEffect(() => {
		detectDeviceByScreenSize();
		window.addEventListener("scroll", detectDeviceByScreenSize);
		window.addEventListener("resize", detectDeviceByScreenSize);

		// Cleanup event listeners when the component is unmounted
		return () => {
			window.removeEventListener("scroll", detectDeviceByScreenSize);
			window.removeEventListener("resize", detectDeviceByScreenSize);
		};
	}, []);
	useEffect(() => {
		init();
	}, []);

	return (
		<>
			{children}
			<PopupsPage />
			<FloatButtonPage />
			<Toaster duration={2000} position="top-center" richColors offset={80} />
		</>
	);
}
